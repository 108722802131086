import React from "react"

import styles from "../MatchHistory/MatchHistory.module.css"
import Match from "../MatchHistory/Match"
import Icon from "./Icon"


class MatchesDropdown extends React.Component {

  state = {
    matchesActive: false,
  }

  toggleMatches = () => {
    this.setState(state => ({ matchesActive: !state.matchesActive }));
  };

  render() {

    const pl = this.props.player

    const width = this.props.width || 'max-w-md'
    const btnBGMessi = this.state.matchesActive ? 'bg-messi-darken5 border-messi' : 'bg-messi-darken10 text-white border-messi hover:bg-messi-darken5 focus:bg-messi-darken5'
    const btnBGRonaldo = this.state.matchesActive ? 'bg-ronaldo-darken5 border-ronaldo-lighten10' : 'bg-ronaldo-darken10 text-white border-ronaldo-lighten10 hover:bg-ronaldo-darken5 focus:bg-ronaldo-darken5'

    return (

      <div className={`w-full mx-auto my-2 ${width}`}>

        <button
          onClick={this.toggleMatches}
          className={`flex justify-between items-center text-left w-full p-4 font-semibold border-2 cursor-pointer focus:outline-none ${pl === "Messi" ? btnBGMessi : btnBGRonaldo}`}
        >
          <h2>{this.props.dropdownText}</h2>
          {this.state.matchesActive ? (
            <><span className={`ml-auto mr-3 uppercase text-xs px-2 py-1 tracking-widest font-light bg-white bg-opacity-25`}>Hide</span><Icon iconName="icon-angle-up" class="text-xl" /></>
          ) : (
            <><span className={`ml-auto mr-3 uppercase text-xs px-2 py-1 tracking-widest font-light bg-white bg-opacity-25`}>Show</span><Icon iconName="icon-angle-down" class="text-xl" /></>
          )}
        </button>

        <div className={`${styles.matchListWrap} mt-6 w-full mx-auto ${this.state.matchesActive ? 'block' : 'hidden'}`}>
          {this.props.data.filter(d => d.node.date).map(d => (
            <Match
              key={d.node.date+d.node.year}
              data={d.node}
              player={pl}
            />
          ))}
        </div>

      </div>

    )
  }
}

export default MatchesDropdown