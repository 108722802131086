import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CompetitionBlock from "../../components/MainStats/CompetitionBlock"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import CopyBlock from "../../components/Copy/CopyBlock"
import InfoBar from '../../components/Utilities/InfoBar'
import MatchesDropdown from "../../components/Utilities/MatchesDropdown"

class ElClasico extends React.Component {

  render() {

    const mdata = this.props.data.allSheetMessiAllTimeStats.edges
    const rdata = this.props.data.allSheetRonaldoAllTimeStats.edges

    let mmatches = this.props.data.allSheetMessiMatchHistory.edges.filter(d => d.node.date && d.node.team === "Barcelona")
    let rmatches = this.props.data.allSheetRonaldoMatchHistory.edges.filter(d => d.node.date && d.node.team === "Real Madrid")

    let [{
      freeKicks: mElFreeKicks,
      goals: mElGoals,
      assists: mElAssists,
      hatTricks: mElHatTricks,
      minsPerGoal: mElMinsPerGoal,
      minsPerGoalContribution: mElMinsPerGoalCont,
      pens: mElPens,
      apps: mElApps,
    }] = mdata.filter(d => d.node.competition === "El Clasico").map(d => d.node)
  
    let [{
      goals: rElGoals,
      assists: rElAssists,
      minsPerGoal: rElMinsPerGoal,
      minsPerGoalContribution: rElMinsPerGoalCont,
      pens: rElPens,
      apps: rElApps,
    }] = rdata.filter(d => d.node.competition === "El Clasico").map(d => d.node)

    return (

      <Layout>
        <SEO
          title={"Messi vs Ronaldo Goals and Stats in El Clasico"}
          description={`Messi's very first hat-trick was against Real Madrid, but who has scored more goals in El Clasico overall, Messi or Ronaldo?`}
          canonicalPath={`/club-stats/el-clasico/`}
        />

        <h1>
          <span className="sr-only">Messi vs Ronaldo</span> El Clasico Stats
        </h1>

        

        <CompetitionBlock competition="El Clasico" type="club" mdata={mdata} rdata={rdata} subtitle="All Competitions" perfmsg="Since 2009/10 (not all Clasicos)" />

        <div className="my-12">
          <MatchesDropdown
            data={mmatches}
            player="Messi"
            dropdownText="All Messi El Clasico Matches"
          />

          <MatchesDropdown
            data={rmatches}
            player="Ronaldo"
            dropdownText="All Ronaldo El Clasico Matches"
          />
        </div>

        <CopyBlock>
          <h2>Messi & Ronaldo El Clásico Stats</h2>

          <InfoBar />

          <p><strong className="text-highlight">El Clásico</strong> is arguably the biggest fixture in club football, with millions tuning in around the globe every time Real Madrid and Barcelona go to battle. What better stage, therefore, to showcase the greatest footballing rivalry of all time: Lionel Messi vs Cristiano Ronaldo?</p>

          <p>Messi has played in La Liga for several more seasons than Ronaldo, so naturally has played in a significantly greater number of El Clásico matches, with <strong className="text-messi">{mElApps} El Clásico appearances</strong>; {Number(mElApps)-Number(rElApps)} more than Ronaldo who has played in <strong className="text-ronaldo">{rElApps} El Clásico matches</strong>.</p>

          <h3>Goals in El Clásico</h3>

          <p>Messi has scored <strong className="text-messi">{mElGoals} goals in El Clásico</strong> in his career which, unsurprisingly, is a record. Ronaldo has scored <strong className="text-ronaldo">{rElGoals} in El Clasico</strong>, which is the joint second most in the fixture's history alongside the legendary Alfredo Di Stéfano.</p>

          <p>Ronaldo has the slight edge in terms of minutes per goal however, scoring <strong className="text-ronaldo">a goal for every {parseFloat(rElMinsPerGoal).toFixed(2)} minutes played</strong> in Real Madrid vs Barcelona matches. Messi is whiskers behind, <strong className="text-messi">scoring a goal every {parseFloat(mElMinsPerGoal).toFixed(2)} minutes</strong>.</p>

          <p>Cristiano Ronaldo has also enjoyed the best goalscoring run in the fixture's history, <strong className="text-ronaldo">scoring in a record 6 consecutive El Clasico fixtures</strong> in 2012 (netting 7 goals in the process).</p>

          <p>When it comes to <Link to={`/detailed-stats/hat-tricks/`} className="text-highlight underline">hat-tricks</Link> though, it's Messi who holds the record, <strong className="text-messi">scoring a hat-trick on {mElHatTricks} occasions</strong> against Real Madrid. The first of which was Messi's first ever hat-trick; his third helping to rescue a point for Barcelona in stoppage time. An apt way to score his first treble and really announce himself on the big stage.</p>

          <p>Ronaldo managed 2 braces against Barcelona for Real Madrid, but was never able to secure a hat-trick.</p>

          <h4>All Time Top Goalscorers in El Clasico</h4>

          <div className="w-full overflow-x-scroll scrolling-touch text-sm">
            <table>
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Player</th>
                  <th>Club</th>
                  <th>Goals</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Lionel Messi</td>
                  <td>Barcelona</td>
                  <td>{mElGoals}</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Cristiano Ronaldo</td>
                  <td>Real Madrid</td>
                  <td>{rElGoals}</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Alfredo Di Stefano</td>
                  <td>Real Madrid</td>
                  <td>18</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Raúl</td>
                  <td>Real Madrid</td>
                  <td>14</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>César</td>
                  <td>Barcelona</td>
                  <td>14</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Francisco Gento</td>
                  <td>Real Madrid</td>
                  <td>14</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Ferenc Puskás</td>
                  <td>Real Madrid</td>
                  <td>14</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>Santillana</td>
                  <td>Real Madrid</td>
                  <td>12</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>Luis Suarez</td>
                  <td>Barcelona</td>
                  <td>11</td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>Hugo Sanchez</td>
                  <td>Real Madrid</td>
                  <td>10</td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3>Assists in El Clásico</h3>

          <p>There is only one winner when it comes to providing <Link to={`/detailed-stats/opta-assists/`} className="text-highlight underline">assists</Link> in El Clasico fixtures. Lionel Messi has made an impressive <strong className="text-messi">{mElAssists} assists</strong> against Real Madrid; yet another El Clasico record. Ronaldo on the other hand, has made just <strong className="text-ronaldo">{rElAssists} assist</strong> in {rElApps} appearances.</p>

          <p>As a result, despite Messi having a slightly inferior minutes per goal ratio, he boasts a far superior ratio in terms of overall goal contributions, registering <strong className="text-messi">a goal or assist every {parseFloat(mElMinsPerGoalCont).toFixed(2)} minutes</strong> in El Clasico matches. Ronaldo's solitary assist puts his ratio at <strong className="text-ronaldo">one goal or assist every {parseFloat(rElMinsPerGoalCont).toFixed(2)} minutes</strong>.</p>

          <h3>Free Kicks and Penalties in El Clásico</h3>

          <p>Both Messi and Ronaldo boast 100% penalty records in El Clasico, with Messi scoring <strong className="text-messi">{mElPens} out of {mElPens}</strong> spot kicks, whilst Ronaldo converted <strong className="text-ronaldo">{rElPens} out of {rElPens}</strong> of his Clasico penalties.</p>

          <p>Messi has also scored <strong className="text-messi">{mElFreeKicks} free kicks</strong> for Barcelona against Real Madrid, whereas Ronaldo was never able to find the net from a direct free kick in the famous fixture.</p>

          <p>If you’re looking for more Messi vs Ronaldo stats, follow us on Twitter at <a className="text-highlight underline" target="_blank" rel="noreferrer" href="https://twitter.com/mvsrapp">@mvsrapp</a>, where we consistenly post exclusive stats.</p>

        </CopyBlock>

        <LastUpdated type="Stats" />
        
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allSheetMessiAllTimeStats(filter: {competition: {eq: "El Clasico"}}) {
      edges {
        node {
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
          freeKickAttempts
          bigChancesCreated
          xg
          fkc
          xgc
        }
      }
    }
    allSheetRonaldoAllTimeStats(filter: {competition: {eq: "El Clasico"}}) {
      edges {
        node {
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
          freeKickAttempts
          bigChancesCreated
          xg
          fkc
          xgc
        }
      }
    }
    allSheetMessiMatchHistory(filter: {opponent: {eq: "Real Madrid"}}) {
      edges {
        node {
          date
          year
          season
          competition
          homeAway
          round
          team
          opponent
          scoreTeam
          scoreOpponent
          goals
          assists
          started
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          rating
          freeKickAttempts
          bigChancesCreated
          xg
          reboundGkAssist
          reboundPostAssist
          deflectedAssist
          penWonAssist
          ownGoalAssist
          allAssists
          ftScore
          penScore
          shootout
          h2H
          opponentLeaguePosition
        }
      }
    }
    allSheetRonaldoMatchHistory(filter: {opponent: {eq: "Barcelona"}}) {
      edges {
        node {
          date
          year
          season
          competition
          homeAway
          round
          team
          opponent
          scoreTeam
          scoreOpponent
          goals
          assists
          started
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          rating
          freeKickAttempts
          bigChancesCreated
          xg
          reboundGkAssist
          reboundPostAssist
          deflectedAssist
          penWonAssist
          ownGoalAssist
          allAssists
          ftScore
          penScore
          shootout
          h2H
          opponentLeaguePosition
        }
      }
    }
  }
`

export default ElClasico
